import * as React from 'react';
import { useEffect, useState } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { useQuery, useMutation } from 'react-query';
import apiClient from '../../../request/http-common';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import { Button } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';

import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import OutlinedInput from '@mui/material/OutlinedInput';
import Box from '@mui/material/Box';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
  });
export default function ListTransfer() {
    const [transfers, setProducts] = useState(null);
    const [fundingAmount, setFundingAmount] = useState(0);
    const [gateway, setGateway] = useState('');
    const [transactionId, setTransactionId] = useState('');
    const [currentTransfer, setCurrentTransfer] = useState(null);

    const [open, setOpen] = React.useState(false);
      const handleClickOpen = () => {
        setOpen(true);
      };
      const handleClose = () => {
        setOpen(false);
      };

    const { isLoading: isLoadingtransfers, refetch: gettransfers } = useQuery(
        "load-transfers",
        async () => {
          return await apiClient.get("/api/load-transfers");
        },
        {
          enabled: false,
          onSuccess: (res) => {
            if (res.data.status === 'success') {
                setProducts(res.data.transfers);
                const event2 = new Event('processed');
                window.dispatchEvent(event2);
            }
          },
          onError: (err) => {
            // console.log(err.response?.data || err);
          },
        }
      );

      const { isLoading: isFuding, mutate: postFunding } = useMutation(
        async () => {
          return await apiClient.post(`/api/update-transfer`, {
            amount: fundingAmount,
            gateway: gateway,
            transfer_id: currentTransfer,
            transaction_id: transactionId
          });
        },
        {
          onSuccess: (res) => {
              if (res.data.status === 'success') {
                localStorage.setItem('notification', JSON.stringify({message : `${res.data.message}`, 'type': 'success' }));
                const event = new Event('newMessage');
                window.dispatchEvent(event);
                
              }            
          },
          onError: (err) => {
                let errorMessage = err.response?.data || err ;
                localStorage.setItem('notification', JSON.stringify({message : `${errorMessage}`, 'type': 'error' }));
                const event = new Event('newMessage');
                window.dispatchEvent(event);
          },
        }
      );

    
      useEffect(() => {
        gettransfers() 
        if (isLoadingtransfers) {
            const event3 = new Event('processing');
            window.dispatchEvent(event3);
        }
      }, []);

  const getDate = (date) => new Date(date).toDateString();
  return (
    <div style={{marginTop: '75px', marginBottom: '40px'}}>
    <Container>
        <Typography  mt={4} mb={2} sx={{ fontWeight: 'bold', fontSize: 20, textAlign: 'center', textTransform: 'uppercase' }} gutterBottom variant="h6" component="div">
        List of Today's Transfers
      </Typography>
    <TableContainer component={Paper}>
        
      {transfers !== null &&
      <Table sx={{ minWidth: '100%' }} size="small" aria-label="a dense table">
      <TableHead>
        <TableRow>
        
        <TableCell>User Name</TableCell>
          <TableCell align="right">User Email</TableCell>
          <TableCell align="right">Status</TableCell>
          <TableCell align="right">Transaction ID</TableCell>
          <TableCell align="right">Edit</TableCell>
          <TableCell align="right">Date</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {transfers.map((transfer) => (
          <TableRow
            key={transfer.id}
            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
          >
            <TableCell component="th" scope="row">
            {transfer.user.name}
            </TableCell>
            <TableCell align="right">{transfer.user.email}</TableCell>
            <TableCell align="right">{transfer.status === 'approved'? <span style={{color: 'green'}}>{transfer.status}</span>: <span style={{color: 'red'}}>{transfer.status}</span>}</TableCell>
            <TableCell align="right">{transfer.reference}</TableCell>
            <TableCell align="right"><Button onClick={()=>{
                setCurrentTransfer(transfer.id); handleClickOpen();
            }} startIcon={<EditIcon />}>Edit</Button></TableCell>
            <TableCell align="right">{getDate(transfer.created_at)}</TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
      }
      
    </TableContainer>
    </Container>

    
    <Dialog
        sx={{zIndex: 40000}}
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle sx={{textAlign: 'center'}}>{"FUND USER WALLET"}</DialogTitle>

        <DialogContent>

          <DialogContentText sx={{textAlign: 'center'}} mb={2} id="alert-dialog-slide-description">
            Kindly enter any amount to fund
          </DialogContentText>

          <Box px={1}>
            <FormControl  sx={{ m: 1, minWidth: '100%' }} size="small">
              <InputLabel htmlFor="amount">Enter Amount To Fund</InputLabel>
              <OutlinedInput
                onChange={(e)=>setFundingAmount(e.target.value)}
                id="amount"
                type={'number'}
                label="Enter Amount To Fund"
              />
            </FormControl>

            <FormControl  sx={{ m: 1, minWidth: '100%' }} size="small">
              <InputLabel htmlFor="transactionId">Transaction ID</InputLabel>
              <OutlinedInput
                onChange={(e)=>setTransactionId(e.target.value)}
                id="transactionId"
                type={'text'}
                label="Transaction ID"
              />
            </FormControl>

            <FormControl  sx={{ m: 1, minWidth: '100%' }} size="small">
              <InputLabel htmlFor="gateway">Gateway</InputLabel>
              <OutlinedInput
                onChange={(e)=>setGateway(e.target.value)}
                id="gateway"
                type={'text'}
                label="Gateway"
              />
            </FormControl>
          </Box>          
        </DialogContent>
        
        <DialogActions>
        <Button onClick={postFunding}  color='success'>{isFuding? 'funding...': 'fund account'}
        </Button>
        </DialogActions>
      </Dialog>

    </div>
  );
}