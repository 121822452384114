import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    redirect: JSON.parse(localStorage.getItem('redirect')) || ''
}

export const historySlice = createSlice({
    name: 'redirect',
    initialState,
    reducers: {
        setRedirect: (state, action) =>{
            state.redirect = action.payload;  
            localStorage.setItem('redirect', JSON.stringify(state.redirect));
        }      
    }
})

export const {setRedirect} = historySlice.actions;
export default historySlice.reducer;