import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    plans: JSON.parse(localStorage.getItem('plans')) || [],
}

export const plansSlice = createSlice({
    name: 'plans',
    initialState,
    reducers: {
        setPlans: (state, action) =>{
            state.plans = action.payload;  
            localStorage.setItem('plans', JSON.stringify(state.plans));
        }       
    }
})

export const {setPlans} = plansSlice.actions;
export default plansSlice.reducer;