import React, {useState, useEffect} from 'react'
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import CardMedia from '@mui/material/CardMedia';
import { CardActions } from '@mui/material';
import Typography from '@mui/material/Typography';
import CardContent from '@mui/material/CardContent';
import { Link as RouterLink } from 'react-router-dom';
import { Link } from '@mui/material';
import Divider from '@mui/material/Divider';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import OutlinedInput from '@mui/material/OutlinedInput';
import { Button } from '@mui/material';
import { useMutation } from "react-query";
import apiClient from '../../request/http-common';
import CircularProgress from '@mui/material/CircularProgress';
import { useDispatch } from 'react-redux';
import Box from '@mui/material/Box';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import { useSelector } from 'react-redux';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import { useQuery } from 'react-query';

const JobApplication = () => {
 
    const initialLogin = {
        first_name : ''	,
        last_name : ''	,
        official_email : ''	,
        whatsapp_phone: '',
        past_work_experience : ''	,
        your_goal : ''	,
    }
    
    
    const [error, setErros] = useState('');
    const [planForm, setPlanForm] = useState(initialLogin);

    const onChange = (e) =>
    setPlanForm({ ...planForm, [e.target.name]: e.target.value });


    const { isLoading: isSendingRequest, mutate: postPlan } = useMutation(

        async () => {
          return await apiClient.post(`/api/job-application`, {
            first_name : planForm.first_name,	
            last_name : planForm.last_name,	
            official_email : planForm.official_email,	
            past_work_experience : planForm.past_work_experience,	
            your_goal : planForm.your_goal,
            whatsapp_phone: planForm.whatsapp_phone
          });
        },
        {
          onSuccess: (res) => {
            localStorage.setItem('notification', JSON.stringify({message : `${res.data.message}`, 'type': 'success' }));
            const event = new Event('newMessage');
            window.dispatchEvent(event);
            const event2 = new Event('processed');
            window.dispatchEvent(event2);
          },
          onError: (err) => {   
            const event2 = new Event('processed');
            window.dispatchEvent(event2);
            if (err.response?.data.length) {
                let myerror = err.response?.data || err;         
            setErros(myerror.errors)               
            }else{
                let errorMessage = err.response?.data.message || err ;
                localStorage.setItem('notification', JSON.stringify({message : `${errorMessage}`, 'type': 'error' }));
                const event = new Event('newMessage');
                window.dispatchEvent(event);
            }    
          },
        }
      );
      const onSubmit = () =>{
        const event3 = new Event('processing');
        window.dispatchEvent(event3);
        postPlan();
      } 


  return (
    <div style={{marginTop: '70px'}}>
        <Grid container px={2} mt={4}  mb={4} rowSpacing={1} columnSpacing={{ xs: 1, sm: 1, md: 1 }}>
            <Grid item xs={12} md={12}>
                <Card sx={{marginX: '5px', borderRadius: '9px', height: '100%'}}>
                    <CardContent>
                    <Typography mt={4} sx={{ fontWeight: 'bold', fontSize: 24, textAlign: 'center'  }} gutterBottom variant="h6" component="div">
                        JOB APPLICATION 
                    </Typography>
                    <Typography  sx={{  textAlign: 'center', fontSize: 16  }} variant="subtitle1" gutterBottom>
                        Fill the form below to get matched to companies looking for your skills
                    </Typography>            

                    <FormControl fullWidth={true} sx={{ m: 1, minWidth: 50 }}>
                            <InputLabel htmlFor="first_name">First Name</InputLabel>
                            {(error !== '' && error.first_name) ?

                            <OutlinedInput
                            onChange={onChange}
                            error
                            id="first_name"
                            type={'text'}
                            name="first_name"
                            label="First Name"
                            helperText={error.first_name}
                            />
                            :
                            <OutlinedInput
                            onChange={onChange}
                            id="first_name"
                            type={'text'}
                            name="first_name"
                            label="First Name"
                            />
                            }
                            <Typography color='error'  sx={{  textAlign: 'left', fontSize: 16  }} variant="subtitle1" gutterBottom>
                            {error !== '' && error.first_name}
                            </Typography>
                        </FormControl>            

                        <FormControl fullWidth={true} sx={{ m: 1, minWidth: 50 }}>
                            <InputLabel htmlFor="last_name">Last Name</InputLabel>
                            {(error !== '' && error.last_name) ?

                            <OutlinedInput
                            onChange={onChange}
                            error
                            id="last_name"
                            type={'text'}
                            name="last_name"
                            label="Last Name"
                            helperText={error.last_name}
                            />
                            :
                            <OutlinedInput
                            onChange={onChange}
                            id="last_name"
                            type={'text'}
                            name="last_name"
                            label="Last Name"
                            />
                            }
                            <Typography color='error'  sx={{  textAlign: 'left', fontSize: 16  }} variant="subtitle1" gutterBottom>
                            {error !== '' && error.last_name}
                            </Typography>
                            
                        </FormControl>

                        

                        <FormControl fullWidth={true} sx={{ m: 1, minWidth: 50 }}>
                          <InputLabel htmlFor="whatsapp_phone">Whatsapp Phone Number</InputLabel>
                          {(error !== '' && error.whatsapp_phone) ?

                          <OutlinedInput
                          onChange={onChange}
                          error
                          id="whatsapp_phone"
                          type={'text'}
                          name="whatsapp_phone"
                          label="Whatsapp Phone Number"
                          helperText={error.whatsapp_phone}
                          />
                          :
                          <OutlinedInput
                          onChange={onChange}
                          id="whatsapp_phone"
                          type={'text'}
                          name="whatsapp_phone"
                          label="Whatsapp Phone Number"
                          />
                          }
                          <Typography color='error'  sx={{  textAlign: 'left', fontSize: 16  }} variant="subtitle1" gutterBottom>
                          {error !== '' && error.whatsapp_phone}
                          </Typography>
                      </FormControl>


                        <FormControl fullWidth={true} sx={{ m: 1, minWidth: 50 }}>
                            <InputLabel htmlFor="past_work_experience">Past Work Experience</InputLabel>
                            {(error !== '' && error.past_work_experience) ? 
                            <OutlinedInput
                            error
                            onChange={onChange}
                            minRows={2} multiline
                            id="past_work_experience"
                            name="past_work_experience"
                            type={'text'}
                            label="Past Work Experience"
                            helperText={error.past_work_experience}
                            />
                            :
                            <OutlinedInput
                            onChange={onChange}
                            minRows={2} multiline
                            id="past_work_experience"
                            name="past_work_experience"
                            type={'text'}
                            label="Past Work Experience"
                            />
                            }
                            <Typography color='error'  sx={{  textAlign: 'left', fontSize: 16  }} variant="subtitle1" gutterBottom>
                            {error !== '' && error.past_work_experience}
                            </Typography>
                        </FormControl>

                        <FormControl fullWidth={true} sx={{ m: 1, minWidth: 50 }}>
                            <InputLabel htmlFor="official_email">Official Email</InputLabel>
                            {(error !== '' && error.official_email) ?

                            <OutlinedInput
                            onChange={onChange}
                            error
                            id="official_email"
                            type={'text'}
                            name="official_email"
                            label="Official Email"
                            helperText={error.official_email}
                            />
                            :
                            <OutlinedInput
                            onChange={onChange}
                            id="official_email"
                            type={'text'}
                            name="official_email"
                            label="Official Email"
                            />
                            }
                            <Typography color='error'  sx={{  textAlign: 'left', fontSize: 16  }} variant="subtitle1" gutterBottom>
                            {error !== '' && error.official_email}
                            </Typography>
                            
                        </FormControl>

                        <FormControl fullWidth={true} sx={{ m: 1, minWidth: 50 }}>
                            <InputLabel htmlFor="your_goal">Your Goal</InputLabel>
                            {(error !== '' && error.your_goal) ?

                            <OutlinedInput
                            onChange={onChange}
                            error
                            id="your_goal"
                            type={'text'}
                            name="your_goal"
                            label="Your Goal"
                            helperText={error.your_goal}
                            />
                            :
                            <OutlinedInput
                            onChange={onChange}
                            id="your_goal"
                            type={'text'}
                            name="your_goal"
                            label="Your Goal"
                            />
                            }
                            <Typography color='error'  sx={{  textAlign: 'left', fontSize: 16  }} variant="subtitle1" gutterBottom>
                            {error !== '' && error.your_goal}
                            </Typography>
                            
                        </FormControl>
                        
                    </CardContent>   

                    <CardActions>
                        <Button onClick={onSubmit} fullWidth={true} size="large" color="primary" variant="contained"> {isSendingRequest? 'Applying...' : 'Apply now'}</Button>
                    </CardActions>
                </Card>
            </Grid>
        </Grid>
    </div>
  )
}

export default JobApplication
