import * as React from 'react';
import { Fragment } from 'react';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { Link as RouterLink } from 'react-router-dom';
import { Link } from '@mui/material';
import TapasIcon from '@mui/icons-material/Tapas';
import AccountBoxIcon from '@mui/icons-material/AccountBox';

export default function Editor() {
  return (
    <Fragment>
        <Link  to='/upload' component={RouterLink} underline="none" color="inherit">
        <ListItem  disablePadding>
            <ListItemButton>
            <ListItemIcon>
            <TapasIcon/>
            </ListItemIcon>
            <ListItemText  primary={`Upload`} />
            </ListItemButton>
        </ListItem>
        </Link>

        <Link  to='/list-products' component={RouterLink} underline="none" color="inherit">
              <ListItem  disablePadding>
                  <ListItemButton>
                  <ListItemIcon>
                  <AccountBoxIcon />
                  </ListItemIcon>
                  <ListItemText  primary={`Products`} />
                  </ListItemButton>
              </ListItem>
            </Link>

        <Link  to='/profile' component={RouterLink} underline="none" color="inherit">
              <ListItem  disablePadding>
                  <ListItemButton>
                  <ListItemIcon>
                  <AccountBoxIcon />
                  </ListItemIcon>
                  <ListItemText  primary={`Profile`} />
                  </ListItemButton>
              </ListItem>
            </Link>

        <Link  to='/list-orders' component={RouterLink} underline="none" color="inherit">
        <ListItem  disablePadding>
            <ListItemButton>
            <ListItemIcon>
            <TapasIcon/>
            </ListItemIcon>
            <ListItemText  primary={`Orders`} />
            </ListItemButton>
        </ListItem>
        </Link>

        <Link  to='/list-transfers' component={RouterLink} underline="none" color="inherit">
        <ListItem  disablePadding>
            <ListItemButton>
            <ListItemIcon>
            <TapasIcon/>
            </ListItemIcon>
            <ListItemText  primary={`Transfers`} />
            </ListItemButton>
        </ListItem>
        </Link>

        

        <Link  to='/meal-setter' component={RouterLink} underline="none" color="inherit">
        <ListItem  disablePadding>
            <ListItemButton>
            <ListItemIcon>
            <TapasIcon/>
            </ListItemIcon>
            <ListItemText  primary={`Set Breakfast`} />
            </ListItemButton>
        </ListItem>
        </Link>


        <Link  to='/set-lunch' component={RouterLink} underline="none" color="inherit">
        <ListItem  disablePadding>
            <ListItemButton>
            <ListItemIcon>
            <TapasIcon/>
            </ListItemIcon>
            <ListItemText  primary={`Set Lunch`} />
            </ListItemButton>
        </ListItem>
        </Link>

        <Link  to='/set-dinner' component={RouterLink} underline="none" color="inherit">
        <ListItem  disablePadding>
            <ListItemButton>
            <ListItemIcon>
            <TapasIcon/>
            </ListItemIcon>
            <ListItemText  primary={`Set Dinner`} />
            </ListItemButton>
        </ListItem>
        </Link>


        <Link  to='/set-featured' component={RouterLink} underline="none" color="inherit">
        <ListItem  disablePadding>
            <ListItemButton>
            <ListItemIcon>
            <TapasIcon/>
            </ListItemIcon>
            <ListItemText  primary={`Set Featured`} />
            </ListItemButton>
        </ListItem>
        </Link>


        <Link  to='/create-categories' component={RouterLink} underline="none" color="inherit">
        <ListItem  disablePadding>
            <ListItemButton>
            <ListItemIcon>
            <TapasIcon/>
            </ListItemIcon>
            <ListItemText  primary={`Create Category`} />
            </ListItemButton>
        </ListItem>
        </Link>
    </Fragment>
  );
}