import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    notifications: JSON.parse(localStorage.getItem('notifications')) || [],
}

export const notificationstSlice = createSlice({
    name: 'notifications',
    initialState,
    reducers: {
        setNotifications: (state, action) =>{
            state.notifications = action.payload;  
            localStorage.setItem('notifications', JSON.stringify(state.notifications));
        },      
    }
})

export const {setNotifications} = notificationstSlice.actions;
export default notificationstSlice.reducer;