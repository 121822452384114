import * as React from 'react';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { Link as RouterLink } from 'react-router-dom';
import { Link } from '@mui/material';
import CardMembershipIcon from '@mui/icons-material/CardMembership';
import HomeIcon from '@mui/icons-material/Home';
import AccountBoxIcon from '@mui/icons-material/AccountBox';

export default function Rider() {
  return (
    <List>
    <Link  to='/' component={RouterLink} underline="none" color="inherit">
        <ListItem  disablePadding>
            <ListItemButton>
            <ListItemIcon>
            <HomeIcon />
            </ListItemIcon>
            <ListItemText  primary={`Home`} />
            </ListItemButton>
        </ListItem>
    </Link>

    <Link  to='/profile' component={RouterLink} underline="none" color="inherit">
        <ListItem  disablePadding>
            <ListItemButton>
            <ListItemIcon>
            <AccountBoxIcon />
            </ListItemIcon>
            <ListItemText  primary={`Profile`} />
            </ListItemButton>
        </ListItem>
    </Link>

    <Link  to='/list-orders' component={RouterLink} underline="none" color="inherit">
        <ListItem  disablePadding>
            <ListItemButton>
            <ListItemIcon>
            <CardMembershipIcon />
            </ListItemIcon>
            <ListItemText  primary={`Deliveries`} />
            </ListItemButton>
        </ListItem>
    </Link>
    </List>
  );
}