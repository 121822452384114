import * as React from 'react';
import { useEffect, useState } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { useQuery } from 'react-query';
import Avatar from '@mui/material/Avatar';
import apiClient from '../../request/http-common';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import { Button } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import { Link as RouterLink } from 'react-router-dom';

export default function AdminJobApplications() {

    const [applications, setapplications] = useState(null);
    const { isLoading: isLoadingapplications, refetch: getapplications } = useQuery(
        "show-job-applications",
        async () => {
          return await apiClient.get("/api/show-job-applications");
        },
        {
          enabled: false,
          onSuccess: (res) => {
            if (res.data.status === 'success') {
                setapplications(res.data.applications);
                const event2 = new Event('processed');
                window.dispatchEvent(event2);
            }
          },
          onError: (err) => {
            // console.log(err.response?.data || err);
          },
        }
      );
    
      useEffect(() => {
        getapplications() 
        if (isLoadingapplications) {
            const event3 = new Event('processing');
            window.dispatchEvent(event3);
        }
      }, []);

  const getDate = (date) => new Date(date).toDateString();
  return (
    <div style={{marginTop: '75px'}}>
    <Container>
        <Typography  mt={4} mb={2} sx={{ fontWeight: 'bold', fontSize: 20, textAlign: 'center', textTransform: 'uppercase' }} gutterBottom variant="h6" component="div">
        
        {isLoadingapplications? 'Loading applications...' : 'List of Job applicationes'}
      </Typography>
    <TableContainer component={Paper}>
        
      {applications !== null &&
      <Table sx={{ minWidth: '100%' }} size="small" aria-label="a dense table">
      <TableHead>
        <TableRow>
        
        <TableCell>User Name</TableCell>
        <TableCell align="right">User Email</TableCell>
        <TableCell align="right">First Name</TableCell>
        <TableCell align="right">Last Name</TableCell>
        <TableCell align="right">Official Email</TableCell>
        <TableCell align="right">Whatsapp phone</TableCell>
        <TableCell align="right">Past Work Experience</TableCell>
        <TableCell align="right">Your Goal</TableCell>
          <TableCell align="right">View User</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {applications.map((application) => (
          <TableRow
            key={application.id}
            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
          >
            <TableCell component="th" scope="row">
            {application.user? application.user.name : 'user deleted'}
            </TableCell>
            <TableCell align="right">{application.user? application.user.email: 'user deleted' }</TableCell>
            <TableCell align="right">{application.first_name}</TableCell>
            <TableCell align="right">{application.last_name}</TableCell>
            <TableCell align="right">{application.official_email}</TableCell>
            <TableCell align="right">{application.whatsapp_phone}</TableCell>
            <TableCell align="right">{application.past_work_experience}</TableCell>
            <TableCell align="right">{application.your_goal}</TableCell>
            <TableCell align="right"><Button component={RouterLink} to={`/user-dashboard/${application.user? application.user.id: '' }`} startIcon={<EditIcon />}>View User</Button></TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
      }
      
    </TableContainer>
    </Container>
    </div>
  );
}